import React, { useState } from 'react';
import Modal from 'react-modal';
import PlusIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useSelector } from 'react-redux';


const customStyles = {
    content: {
      right: 'auto',
      bottom: 'auto'
    },
  };

export const RelatedProducts = ({ returnRelated, related  }) => {

    const { productos } = useSelector( state => state.productos );

    const getProd = ( id ) => {
        return productos.find( prod => prod.id === id );
    }

    const [ productsRelated, setProductsRelated ] = useState( related );
    const [ productsFiltered, SetProductsFiltered ] = useState( productos);

    const initialState = { search: '' };
    const [ formValues, setFormValues ] = useState( initialState );

    const { search } = formValues;

    function openModal() {
    setIsOpen(true);
    }

    function closeModal() {
    setIsOpen(false);
    }

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [ target.name ]: target.value
        })
    }

    const handleSearch = () => {
        SetProductsFiltered( productos.filter(prod => prod.titulo.toLowerCase().includes(search.toLowerCase())) );
    }

    const handleSubmit = ( e ) => {
        e.preventDefault();

        handleSearch();
    }

    const removeRelated = ( id ) => {
        setProductsRelated( productsRelated.filter( function( p ) { return  p.rel !==  id } ) );
    }

    const addRelated = ( p ) => {
        setProductsRelated( [...productsRelated, {   id:+ new Date(), rel: p.id } ] );
    }

    const handleGuardar = () => {
        returnRelated( productsRelated );
        setFormValues( initialState );
        closeModal();
    }

    Modal.setAppElement('#root');
    const [modalIsOpen, setIsOpen] = useState(false);

    return (
        <div className="simple-row complete">

            <div className="related" onClick={ openModal }>
                <PlusIcon /> Productos Relacionados
            </div>

            <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            className="modal"
            overlayClassName="modal-fondo"
            closeTimeoutMS={ 200 }
            >

            <div className="modal-container">
            <CloseIcon className="close" onClick={ closeModal } />
            <div className="header">
                <span>Relacionados</span>
            </div>
            
                <div className="related-container">

                <ul className="related-list">
                        {
                            productsRelated.length !== 0
                            ?
                            productsRelated.map( p => (

                                <li key={ getProd( p.rel ).id }>
                                    <RemoveIcon onClick={ () => removeRelated( getProd( p.rel ).id ) } />
                                    <span className="titulo">{ getProd( p.rel ).titulo }</span>
                                </li>
                            ))
                            :
                            <div className="label">No hay productos relacionados</div> 
                        }
                    </ul>

                    </div>

                    <div className="header search">
                        <span>Agregar</span>
                    </div>
                
                    <form onSubmit={ handleSubmit } className="related">
                        <input name="search"
                                value={ search }
                                onChange={ handleInputChange }
                                placeholder="Filtrar"
                                autoComplete="off"
                                className="input-line"   />
                                <FindInPageIcon onClick={ handleSubmit }/>
                    </form>

                <div className="related-container">


                    <ul className="related-list">
                        {
                        productsFiltered.length !== 0
                            ?
                            productsFiltered.map( p => (
                                <li key={ p.id }>
                                    <PlusIcon onClick={ () => addRelated( p ) }/>
                                    <span className="titulo">{ p.titulo }</span>
                                </li>
                            )) :
                            <div className="label">
                                No hay resultados
                            </div>
                        }
                    </ul>

                </div>

                <div className="footer">
                    <button onClick={ handleGuardar } >Guardar</button>
                    <button onClick={ closeModal } className="cancel">Cancelar</button>
                </div>

            </div>

            </Modal>
        </div>

    )
}
