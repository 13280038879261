import React, { useEffect, useState } from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usuariosStartLoading } from '../actions/usuarios';
import { UsuarioRow } from './TableRow';
import SearchIcon from '@material-ui/icons/Search';

export const UsuariosScreen = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( usuariosStartLoading() )
        
    }, [ dispatch ])

    const { usuarios } = useSelector( state => state.usuarios );

    const total = usuarios ? usuarios.length : 0;
    const totalActivos = usuarios ? usuarios.filter( u =>  u.activo ).length : 0;

    const [ search, SetSearch ] = useState('');
    const [ filteredRes, setFilteredRes ] = useState([ ]);

    const handleSearch = ({ target }) => {
        SetSearch( target.value )
        triggerSearch();
    }

    const triggerSearch = () => {
        let filteredBySearch = usuarios.filter(u => u.user.toLowerCase().includes(search.toLowerCase()))
        setFilteredRes( filteredBySearch )
    }

    useEffect(() => {
        setFilteredRes( usuarios );
    }, [ usuarios ])

    return (
        <section className="usuarios__wrapper">

            <h2 className="title">Usuarios</h2>
            <p className="bajada">Desde este panel se puede añadir, editar, eliminar e inactivar un usuario.</p>

            <div className="row">
            <div style={{ display: 'flex' }}>
                <div className="info-button">
                    <span>{ total }</span> Usuarios creados
                </div>
                <div className="info-button">
                    <span>{ totalActivos }</span> Usuarios activos
                </div>
            </div>
                <Link className="active-button" to="/usuarios/add">
                    <PersonAddIcon />
                    Crear un nuevo usuario
                </Link>

            </div>

            <div className="filter">
                <input 
                    className="nav-desktop-search-input"
                    placeholder="Buscar"
                    type="text"
                    name="search"
                    value={ search }
                    onChange={ handleSearch }
                    autoComplete="off"
                />
            <SearchIcon className="icon" onClick={ () => triggerSearch } />
            </div>

            { total > 0 &&
            
            <div className="table usuarios">
                <div className="table-header">
                    USUARIO
                </div>
                <div className="table-header">
                    NOMBRE Y APELLIDO
                </div>
                <div className="table-header actions">
                    ACCIONES
                </div>

                <div className="row table-header-actions">
                    <span>activo</span>
                    <span>editar</span>
                    <span>eliminar</span>
                </div>
                    {
                        filteredRes.map(
                            u => ( <UsuarioRow
                                key={ u.id }
                                usuario={ u }
                            /> )
                        )
                    }
            </div>
            }

        </section>
    )
}
