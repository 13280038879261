import React, { useState } from 'react';
import { IconActionSet } from './Widgets';

export const UsuarioRow = ({ usuario }) => {

    const { user, name } = usuario;
    const nombre = name.split(' ');

    const first = nombre[0].charAt(0)
    const second = nombre[1]?.charAt(0) || '';

    const avatar = ( first + second ).toUpperCase();

    return (
        <>
            <div className="table-row user">
                    <div className="avatar">{ avatar }</div>
                    <div className="username">{ user }</div>
                </div>
                
                <div className="table-row name">{ name }</div>
                <div className="table-row icon-set">
                    
                    <IconActionSet origen='usuarios' payload={ usuario } />
                </div>
        </>
    )
}

export const CategoriaRow = ({ categoria }) => {

    const { titulo, orden } = categoria;
    return (
        <>
                <div className="table-row">
                  {orden} -  { titulo }
                </div>

                <div className="table-row">
                    <IconActionSet origen='categorias' payload={ categoria } />
                </div>
        </>
    )
}


export const SliderRow = ({ slider }) => {

    const { titulo } = slider;
    return (
        <>
                <div className="table-row">
                    { titulo }
                </div>

                <div className="table-row">
                    <IconActionSet origen='slider' payload={ slider } />
                </div>
        </>
    )
}


export const FaqRow = ({ faq }) => {

    const { pregunta } = faq;
    return (
        <>
                <div className="table-row">
                    { pregunta }
                </div>

                <div className="table-row">
                    <IconActionSet origen='faq' payload={ faq } />
                </div>
        </>
    )
}


export const NovedadRow = ({ novedad }) => {

        const { titulo } = novedad;
        return (
            <>
                    <div className="table-row">
                        { titulo }
                    </div>
    
                    <div className="table-row">
                        <IconActionSet origen='novedad' payload={ novedad } />
                    </div>
            </>
        )
    }
    

    export const ProductoRow = ({ producto }) => {

        const { titulo, parentGroup, parentCategory } = producto;

        const [ categoria, setCategoria ] = useState( '' );

        const url = `https://api.dworldmachine.com.ar/api/category/${ parentCategory }`;
        fetch( url )
        .then( response => { return response.json() } )
        .then( resp => {

            if ( resp.categoria && resp.categoria.titulo ) {
                setCategoria( resp.categoria.titulo );
            }
        } )

        return (
            <>
                <div className="table-row">
                    { titulo }
                </div>

                <div className="table-row">
                    { categoria }
                </div>

                <div className="table-row">
                    { parentGroup }
                </div>
                
                <div className="table-row">
                    <IconActionSet origen='producto' payload={ producto } />
                </div>
            </>
        )
    }
