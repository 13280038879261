import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import { categoriasStartLoading } from '../actions/categorias';
import { CategoriaRow } from './TableRow';
import SearchIcon from '@material-ui/icons/Search';

export const ProductosCategorias = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( categoriasStartLoading() )
        
    }, [ dispatch ])
    
    const { categorias } = useSelector( state => state.categorias );

    const total = categorias ? categorias.length : 0;
    const totalActivos = categorias ? categorias.filter( c =>  c.activo ).length : 0;

    const [ search, SetSearch ] = useState('');
    const [ filteredRes, setFilteredRes ] = useState([ ]);

    const handleSearch = ({ target }) => {
        SetSearch( target.value )
        triggerSearch();
    }

    const triggerSearch = () => {
        let filteredBySearch = categorias.filter(c => c.titulo.toLowerCase().includes(search.toLowerCase()))
        setFilteredRes( filteredBySearch )
    }

    useEffect(() => {
        setFilteredRes( categorias );
    }, [ categorias ])

    return (
        <section className="productos__wrapper">

            <div className="row">
                <h2 className="title">Categorías de productos</h2>
                <Link to="/productos/add" className="button-volver"><ArrowBackIcon /> Volver</Link>
            </div>
            <p className="bajada">Desde este panel podrá añadir, editar, activar y eliminar categorías de productos.</p>

            <div className="row">
                <div style={{ display: 'flex' }}>
                    <div className="info-button">
                        <span>{ total }</span> Categorías creadas
                    </div>
                    <div className="info-button">
                        <span>{ totalActivos }</span> Categorías activas
                    </div>
                </div>
                    
                <Link className="active-button" to="/productos/categorias/add">
                    <AddIcon />
                    Crear nueva categoría
                </Link>

            </div>

            <div className="filter">
                <input 
                    className="nav-desktop-search-input"
                    placeholder="Buscar"
                    type="text"
                    name="search"
                    value={ search }
                    onChange={ handleSearch }
                    autoComplete="off"
                />
            <SearchIcon className="icon" onClick={ () => triggerSearch } />
            </div>

            <div className="table categorias">
                <div className="table-header">
                    CATEGORIA
                </div>
                <div className="table-header actions">
                    ACCIONES
                </div>

                <div className="row table-header-actions">
                    <span>activa</span>
                    <span>editar</span>
                    <span>eliminar</span>
                </div>
                {
                    filteredRes.map(
                        c => ( <CategoriaRow
                            key={ c.id }
                            categoria={ c }
                        /> )
                    )
                }
            </div>


        </section>
    )

}
