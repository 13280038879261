import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faqsStartLoading } from '../actions/faqs';
import { FaqRow } from './TableRow';
import SearchIcon from '@material-ui/icons/Search';

export const FaqsScreen = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( faqsStartLoading() )
        
    }, [ dispatch ])

    const { faqs } = useSelector( state => state.faqs );

    const total = faqs ? faqs.length : 0;
    const totalActivos = faqs ? faqs.filter( f =>  f.activo ).length : 0;

    const [ search, SetSearch ] = useState('');
    const [ filteredRes, setFilteredRes ] = useState([ ]);

    const handleSearch = ({ target }) => {
        SetSearch( target.value )
        triggerSearch();
    }

    const triggerSearch = () => {
        let filteredBySearch = faqs.filter(f => f.pregunta.toLowerCase().includes(search.toLowerCase()))
        setFilteredRes( filteredBySearch )
    }

    useEffect(() => {
        setFilteredRes( faqs );
    }, [ faqs ])

    return (
        <section className="faqs__wrapper">

            <h2 className="title">Preguntas frecuentes</h2>
            <p className="bajada">Desde este panel se puede añadir, editar, eliminar, activar e inactivar las preguntas frecuentes.</p>

            <div className="row">
                <div style={{ display: 'flex' }}>
                    <div className="info-button">
                        <span>{ total }</span> Preguntas creadas
                    </div>
                    <div className="info-button">
                        <span>{ totalActivos }</span> Preguntas activas
                    </div>
                        
                </div>
                <Link className="active-button" to="/faqs/add">
                    <AddIcon />
                    Crear una nueva pregunta
                </Link>
            </div>

            <div className="filter">
                <input 
                    className="nav-desktop-search-input"
                    placeholder="Buscar"
                    type="text"
                    name="search"
                    value={ search }
                    onChange={ handleSearch }
                    autoComplete="off"
                />
            <SearchIcon className="icon" onClick={ () => triggerSearch } />
            </div>

            <div className="table faqs">
                <div className="table-header">
                    PREGUNTA FRECUENTE
                </div>
                <div className="table-header actions">
                    ACCIONES
                </div>
                
                <div className="row table-header-actions">
                    <span>activa</span>
                    <span>editar</span>
                    <span>eliminar</span>
                </div>
                {
                    filteredRes.map(
                        fq => ( <FaqRow
                            key={ fq.id }
                            faq={ fq }
                        /> )
                    )
                }

            </div>
            
        </section>
    )
}


