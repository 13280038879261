import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { categoriasStartLoading } from '../actions/categorias';
import { productosStartLoading } from '../actions/productos';
import { ProductoRow } from './TableRow';
import SearchIcon from '@material-ui/icons/Search';

export const ProductosScreen = () => {


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( productosStartLoading() )
        dispatch( categoriasStartLoading() )
        
    }, [ dispatch ])

    const { productos } = useSelector( state => state.productos );

    const total = productos ? productos.length : 0;
    const totalActivos = productos ? productos.filter( u =>  u.activo ).length : 0;

    const [ search, SetSearch ] = useState('');
    const [ filteredRes, setFilteredRes ] = useState([ ]);

    const handleSearch = ({ target }) => {
        SetSearch( target.value )
        triggerSearch();
    }

    const triggerSearch = () => {
        let filteredBySearch = productos.filter(prod => prod.titulo.toLowerCase().includes(search.toLowerCase()))
        setFilteredRes( filteredBySearch )
    }

    useEffect(() => {
        setFilteredRes( productos );
    }, [ productos ])

    return (
        <section className="productos__wrapper">

            <h2 className="title">Productos</h2>
            <p className="bajada">Desde este panel se puede añadir, editar, eliminar, activar e inactivar una categoría, grupo de productos o producto.</p>

            <div className="row">

                <div style={{ display: 'flex' }}>
                    <div className="info-button">
                        <span>{ total }</span> Productos creados
                    </div>
                    <div className="info-button">
                        <span>{ totalActivos }</span> Productos activos
                    </div>

                </div>
                
                <Link className="active-button" to="/productos/add">
                    <AddIcon />
                    Crear un nuevo producto
                </Link>

            </div>

            <div className="filter">
                <input 
                    className="nav-desktop-search-input"
                    placeholder="Buscar"
                    type="text"
                    name="search"
                    value={ search }
                    onChange={ handleSearch }
                    autoComplete="off"
                />
            <SearchIcon className="icon" onClick={ () => triggerSearch } />
            </div>

            <div className="table productos">
                <div className="table-header">
                    PRODUCTO
                </div>
                <div className="table-header">
                    CATEGORIA
                </div>
                <div className="table-header">
                    GRUPOD DE PRODUCTOS
                </div>
                <div className="table-header actions">
                    ACCIONES
                </div>

                <div className="row table-header-actions">
                    <span>Activo</span>
                    <span>editar</span>
                    <span>eliminar</span>
                </div>

                { filteredRes &&
                    filteredRes.map(
                        p => ( <ProductoRow
                            key={ p.id }
                            producto={ p }
                        /> )
                    )
                }

            </div>
            
        </section>
    )
}

