import React, { useState } from 'react';
import Modal from 'react-modal';
import PlusIcon from '@material-ui/icons/ControlPoint';
import CloseIcon from '@material-ui/icons/Close';

const customStyles = {
  content: {
    right: 'auto',
    bottom: 'auto'
  },
};

export const TableRowModal = ({ addGrupo }) => {

Modal.setAppElement('#root');
const [modalIsOpen, setIsOpen] = useState(false);

const initialState = { campo: '', id: '' };
const [ formValues, setFormValues ] = useState( initialState );
const { grupo } = formValues;

function openModal() {
  setIsOpen(true);
}

function closeModal() {
  setIsOpen(false);
}

const handleInputChange = ({ target }) => {
    setFormValues({
        ...formValues,
        [ target.name ]: target.value
    })
}

const handleSubmit = ( e ) => {
  e.preventDefault();
}

const handleGuardar = () => {

    if ( grupo && grupo.trim() !== '') {
    const g = { id:+ new Date(), titulo: grupo }
    addGrupo( g );
  }

  setFormValues( initialState );
  closeModal();
}



    return (

    <>
        <button className="add-row" onClick={ openModal }>
            <PlusIcon />Agregar
        </button>
    
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className="modal"
        overlayClassName="modal-fondo"
        closeTimeoutMS={ 200 }
        >

        <div className="modal-container">
          
          <CloseIcon className="close" onClick={ closeModal } />
          <div className="header">
              <span>Agregar grupo</span>
          </div>
          
          <div className="img-container">
          
          <form onClick={ handleSubmit }>
            <input name="grupo"
                    value={ grupo }
                    onChange={ handleInputChange }
                    placeholder="Grupo"
                    autoComplete="off"
                    className="input-line"    />
        </form>
          </div>

          <div className="footer">
              <button onClick={ handleGuardar } >Guardar</button>
              <button onClick={ closeModal } className="cancel">Cancelar</button>
          </div>
      </div>


        </Modal>
    </>
    )

  }

