import Swal from 'sweetalert2';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';

export const usuariosStartAddNew = ( usuario ) => {

    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'users/new', usuario, 'POST' );
            const body = await resp.json();
    
            if( body.ok ) {
                Swal.fire({
                    title: `Usuario cargado`,
                    icon: 'success'
                })
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

export const setUsuarioEdit = ( usuario ) => ({
    type: types.usuarioEdit,
    payload: usuario
})


export const usuariosStartEdit = ( usuario ) => {

    return async( dispatch ) => {

    try {

        const resp = await fetchSinToken( `users/${ usuario.id }`, usuario, 'PUT' );
        const body = await resp.json();

        if( body.ok ) {
            Swal.fire({
                title: `El registro fue actualizado`,
                icon: 'success'
            })

            dispatch( usuariosUpdate( body.user ) )
        }else{
            Swal.fire('Error', body.msg, 'error');
        }


    } catch (error) {
        console.log( error );
        Swal.fire('Error', 'Error en la DB', 'error');
    }

    }
}

export const usuariosUpdate = ( user ) => ({
        type: types.usuariosUpdate,
        payload: user    
})


export const usuariosStartLoading = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'users' );
            const body = await resp.json();
    
            if( body.ok ) {
                dispatch( usuariosLoaded( body.usuarios ) )
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }
    }
}

const usuariosLoaded = ( usuarios ) => ({
    type: types.usuariosLoaded,
    payload: usuarios
})


export const usuariosStartDelete = ( id ) => {

    return async( dispatch ) => {
        try {

            const resp = await fetchSinToken( `users/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire({
                    title: `El registro fue eliminado`,
                    icon: 'success'
                })
                
               dispatch( usuariosDelete( id ) )
            }else{
                Swal.fire('Error', body.msg, 'error');
            }


        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

const usuariosDelete = ( id ) => ({
    type: types.usuariosDelete,
    payload: id
})