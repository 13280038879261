import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { usuariosReducer } from './usuariosReducer';
import { categoriasReducer } from './categoriasReducer';
import { slidersReducer } from './slidersReducer';
import { faqsReducer } from './faqsReducer';
import { novedadesReducer } from './novedadesReducer';
import { productosReducer } from './productosReducer';



export const rootReducer = combineReducers({
    auth: authReducer,
    usuarios: usuariosReducer,
    categorias: categoriasReducer,
    sliders: slidersReducer,
    faqs: faqsReducer,
    novedades: novedadesReducer,
    productos: productosReducer
})