import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';

import { LoginScreen } from '../components/LoginScreen';
import { AdminRoutes } from './AdminRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { persistirLogin } from '../actions/auth';

export function AppRouter() {

  const dispatch = useDispatch();

  const isLogged = localStorage.getItem('logged');
  const uid = localStorage.getItem('uid');
  const user = localStorage.getItem('user');

  useEffect(() => {
    
    dispatch( persistirLogin( uid, user, isLogged ) );

  }, [ dispatch, uid, user, isLogged ])


  const { logged } = useSelector( state => state.auth);

  return (
    
    <Router>

      <Switch>

        <PublicRoute
            exact
            path="/login"
            component={ LoginScreen }
           isAuthenticated={ logged }
            />

        <PrivateRoute
            path="/"
            component={ AdminRoutes }
           isAuthenticated={ logged }
        />

      </Switch>

    </Router>

  );
}