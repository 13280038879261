import React, { useState } from 'react';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import Swal from 'sweetalert2';
import { FeaturesModal } from '../components/FeaturesModal';

export const FeaturesSimpleTable = ({ features, returnFeatures }) => {

    const [ feat, setFeat ] = useState( features );

    const handleRemove = ( id ) => {

        Swal.fire({
            title: `Estas seguro de que desea remover la característica?`,
            showCancelButton: true,
            icon: 'warning',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Remover',
          }).then((result) => {
            if (result.isConfirmed) {
                
                const retFeat = feat.filter(function( f ) { return f.id !== id } );
                setFeat( feat.filter(function( f ) { return f.id !== id } ) );
                returnFeatures( retFeat );
                
            }
          })
    }

    const handleAdd = ( f ) => {

        const retFeat = [ ...feat, f ];
        setFeat( [ ...feat, f] );
        returnFeatures( retFeat );
    }

    
    return (

        <div className="simple-table mtb-0">
            <div className="simple-row header">
                Característica
            </div>
            <div className="simple-row header">
                Valor
            </div>
            {
                feat.map( f => (

                   <Row key={ f.id }
                        carac={ f.carac }
                        valor={ f.valor }
                        id={ f.id }
                        handleRemove={ handleRemove }
                   />
                ))
            }

        <FeaturesModal handleAdd={ handleAdd } />

    </div>
    )
}



const Row = ({ carac, valor, id, handleRemove }) => {

    return (
        <>
            <div className="simple-row">
                { carac }
            </div>
            <div className="simple-row">
                { valor }
                <span onClick={ () => handleRemove( id ) }><RemoveIcon /></span>
            </div>
        </>
    )

}