import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { usuariosStartDelete, setUsuarioEdit, usuariosStartEdit } from '../actions/usuarios';
import { Link } from 'react-router-dom';
import { categoriasStartDelete, setCategoriaEdit, categoriasStartEdit } from '../actions/categorias';
import { slidersStartDelete, setSliderEdit, slidersStartEdit } from '../actions/sliders';
import { faqsStartDelete, setFaqEdit, faqsStartEdit } from '../actions/faqs';
import { novedadesStartDelete, setNovedadEdit, novedadesStartEdit } from '../actions/novedades';
import { productosStartDelete, setProductoEdit, productosStartEdit } from '../actions/productos';
import { FileUpload } from './FileUpload';
import { OneFileUpload } from './OneFileUpload';
import { PdfUpload } from './PdfUpload';
import moment from 'moment';

export const IconActionSet = ({ origen, payload={ id: null, activo: true } }) => {

    const dispatch = useDispatch();
    const accion = payload.activo ? 'desactivar' : 'activar';

    let path;
    switch ( origen ) {

        case 'categorias':
            path = 'productos/'+origen;
        break;

        case 'faq':
            path = 'faqs';
        break;

        case 'novedad':
            path = 'novedades';
        break;
        
        case 'producto':
            path = 'productos';
        break;

        default:
           path = origen;
    }


    const handleVisible = () => {
        Swal.fire({
            title: `Estas seguro de que desea ${ accion }?`,
            showCancelButton: true,
            icon: 'question',
            cancelButtonText: 'No',
            confirmButtonText: 'Sí',
          }).then((result) => {

            if (result.isConfirmed) {
                
                const activo = payload.activo;
                const row = { ...payload, activo: !activo };
                
                switch ( origen ) {

                    case 'usuarios':
                        dispatch( usuariosStartEdit( row ) );
                    break;

                    case 'categorias':
                        dispatch( categoriasStartEdit( row ) );
                    break;

                    case 'slider':
                        dispatch( slidersStartEdit( row ) );
                    break;

                    case 'faq':
                        dispatch( faqsStartEdit( row ) );
                    break;

                    case 'novedad':
                        dispatch( novedadesStartEdit( row ) );
                    break;

                    case 'producto':
                        dispatch( productosStartEdit( row ) );
                    break;

                    default:
                    }

            }
          })
    }

    const handleDelete = () => {
        Swal.fire({
            title: `Estas seguro de que desea eliminar?`,
            showCancelButton: true,
            icon: 'warning',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar',
          }).then((result) => {
            if (result.isConfirmed) {

                switch ( origen ) {

                    case 'usuarios':
                        dispatch( usuariosStartDelete( payload.id ) );
                    break;

                    case 'categorias':
                        dispatch( categoriasStartDelete( payload.id ) );
                    break;
                    
                    case 'slider':
                        dispatch( slidersStartDelete( payload.id ) );
                    break;
              
                    case 'faq':
                        dispatch( faqsStartDelete( payload.id ) );
                    break;
              
                    case 'novedad':
                        dispatch( novedadesStartDelete( payload.id ) );
                    break;
              
                    case 'producto':
                        dispatch( productosStartDelete( payload.id ) );
                    break;

                    default:
                    }
                
            }
          })

    }

    const handlePreset = () => {

        switch ( origen ) {

            case 'usuarios':
                dispatch( setUsuarioEdit( payload ) );
            break;

            case 'categorias':
                dispatch( setCategoriaEdit( payload ) );
            break;

            case 'slider':
                dispatch( setSliderEdit( payload ) );
            break;

            case 'faq':
                dispatch( setFaqEdit( payload ) );
            break;

            case 'novedad':
                dispatch( setNovedadEdit( payload ) );
            break;

            case 'producto':
                dispatch( setProductoEdit( payload ) );
            break;

            default:
            }

    }


    return (
        <div className="icon-set">
            <div className="icon"
                onClick={ handleVisible }
                >
                    { payload.activo ?
                    <VisibilityOutlinedIcon />
                    :
                    <VisibilityOffOutlinedIcon />
                    }
                </div>                            
            <div className="icon">
            
                <Link to={ `/${path}/edit/${payload.id}` }
                    onClick={ handlePreset }
                >
                <EditIcon />
                </Link>

                </div>                                              
            <div className="icon"
                onClick={ handleDelete }>
                    <DeleteIcon />
                </div>
        </div>
    )
}


export const PdfInput = ({ returnPdfFile }) => {
    return (
        <PdfUpload returnPdfFile={ returnPdfFile }/>
    )
}

export const ImageInput = ({ label, pholder, returnFuncOneImage }) => {
    return (
        <div className="custom-input">
            <OneFileUpload label={ label } pholder={ pholder } returnFuncOneImage={ returnFuncOneImage } />
        </div>
    )

}

export const MultipleImagesInput = ({ label, pholder, returnFuncImages, payload }) => {

    return (
        <div className="custom-input">
            <FileUpload label={ label } pholder={ pholder } returnFuncImages={ returnFuncImages } payload={ payload } />
        </div>
    )
}


export const LinkInput = ({ label, pholder, name, payload, handleInputChange }) => {

    return (
        <div className="custom-input">
            <p className="label">{ label }</p>
            <div className="bloque">
                <LinkIcon className="icon" />
                <input
                    className="custom"
                    type="text" 
                    placeholder={ pholder }
                    autoComplete="off"
                    name={ name }
                    value={ payload }
                    onChange={ handleInputChange }
                />
            </div>
        </div>  
    )
}

export const DateInput = ({ label, pholder, name, payload, handleInputChange }) => {

    const date =  moment(payload).format('D/MM/YYYY');


    return (
        <div className="custom-input">
            <p className="label">{ label }</p>
            <div className="bloque">
                <CalendarTodayIcon className="icon" />
                <input
                    className="custom date"
                    type="date" 
                    placeholder={ pholder }
                    name={ name }
                    value={ date }
                    onChange={ handleInputChange }
                    autoComplete="off"
                />
            </div>
        </div>  
    )
}

export const PriceInput = ({ currency, label, pholder, name, payload, handleInputChange }) => {

    return (
        <div className="custom-input">
        <p className="label">{ label }</p>
        <div className={ currency==='peso' ? "bloque" : "bloque dolar" }
        >
            <AttachMoneyIcon className="icon" />
            <input
                className="custom"
                type="text" 
                placeholder={ pholder }
                name={ name }
                value={ payload }
                onChange={ handleInputChange }
                autoComplete="off"
            />
        </div>
    </div>
    )

}

export const VistasInput = ({ label, pholder, name, payload, handleInputChange }) => {

    return (
    <div className="custom-input">
        <p className="label">{ label }</p>
        <div className="bloque vistas">
            <input
                className="custom"
                type="text" 
                placeholder={ pholder }
                name={ name }
                value={ payload }
                onChange={ handleInputChange }
                autoComplete="off"
            />
            </div>
    </div>
    )
}