import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ForumIcon from '@material-ui/icons/Forum';
import PublicIcon from '@material-ui/icons/Public';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import LanguageIcon from '@material-ui/icons/Language';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { startLogout } from '../actions/auth';

export const AsideScreen = () => {

    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const { user } = useSelector(state => state.auth );


    const handleLogout = () => {
        dispatch( startLogout() );
    }

    return (
        <div className="aside__aside">
            <div className="aside__logo"
                style={{ backgroundImage: (`url("/assets/images/logo-blanco.svg")`) }}
            >
            </div>
        
            <h2>Panel</h2>
            <h2>Administrador</h2>
        
            <nav>

                <NavLink exact to="/">
                    <HomeIcon /><span>Inicio</span>
                </NavLink>

                <NavLink to="/usuarios">
                    <PersonIcon /><span>Usuarios</span>
                </NavLink>      
                <NavLink to="/productos">
                    
                    <div className="icon"
                         style={{ backgroundImage: location.includes('/productos')
                         ? (`url("/assets/images/products-orange.svg")`)
                         : (`url("/assets/images/products-white.svg")`) }}
                    >
                    </div>

                    <span className="svg-sider">Productos</span>
                </NavLink>
                          
                <NavLink to="/slider">
                    <PhotoLibraryIcon /><span>Slider home</span>
                </NavLink>

                <NavLink to="/novedades">
                    <DescriptionIcon /><span>Novedades</span>
                </NavLink>                
                <NavLink to="/faqs">
                    <ForumIcon /><span>Preguntas frecuentes</span>
                </NavLink>
                <NavLink to="/lang">
                    <LanguageIcon /><span>Idiomas</span>
                </NavLink>
                <a href="https://app.dworldmachine.com.ar"><PublicIcon /><span>Sitio web</span></a>

                <Link to="#" onClick={ handleLogout }>
                    <CancelOutlinedIcon /><span>Cerrar sesión</span>
                </Link>

                <div className="aside__usuario-activo">
                    <div>
                    Usuario activo:
                    <p>{ 
                    user ? user : 'username'
                    
                    }</p>
                    </div>
                </div>
                

            </nav>
        
        </div>
    )
}
