import { types } from "../types/types";

const initialState = {
    categorias: [],
    categoriaEdit: {}
}

export const categoriasReducer = ( state=initialState, action ) => {

    switch ( action.type ) {

        case types.categoriasLoaded:
            return {
                ...state,
                categorias: [ ...action.payload ]
            }

        case types.categoriaEdit:
            return {
                ...state,
                categoriaEdit: { ...action.payload }
            }

        case types.categoriasDelete:
                return {
                    ...state,
                    categorias: state.categorias.filter(
                        u => ( u.id !== action.payload )
                    )
                }

        case types.categoriasUpdate:
            return {
                ...state,
                categorias: state.categorias.map(
                    u => ( u.id === action.payload.id ) ? action.payload : u
                )
            }

        default:
            return state;
    }


}