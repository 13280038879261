import React, { useState } from 'react'
import SyncIcon from '@material-ui/icons/Sync';
import firebaseApp from '../credentials/firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
const storage= getStorage( firebaseApp );

export const PdfUpload = ({ returnPdfFile }) => {

    const [cargando, SetCargando] = useState( false );

    const handleClick = () => {
        document.querySelector('#pdfSelector').click();
    }

    async function handlePdfChange( e ) {

        const file = e.target.files[0];
        
        if( file ) {
            
            SetCargando( true );
            const fileRef = ref( storage, `pdfs/${ file.name }` );
            await uploadBytes( fileRef, file );
            
            const url = await getDownloadURL( fileRef );

            SetCargando( false );
            returnPdfFile( url );
         }

    }


    return (
        <>
        <input
            id="pdfSelector"
            type="file"
            name="file"
            style={{ display: 'none' }}
            onChange={ handlePdfChange }
        />


        <span className="label-ficha" onClick={ handleClick }>
        {
            cargando &&
            <div className="loader-pdf"><SyncIcon className="rotate"/></div>
        }
            <span className="input-label">Especificaciones</span>
            <div className="ficha-upload"><PictureAsPdfIcon />Subir Ficha Técnica en .PDF</div>
        </span>
        </>
    )
}
