import { types } from "../types/types";

const initialState = {
    novedades: [],
    novedadEdit: {}
}

export const novedadesReducer = ( state=initialState, action ) => {

    switch ( action.type ) {

        case types.novedadesLoaded:
            return {
                ...state,
                novedades: [ ...action.payload ]
            }

        case types.novedadEdit:
            return {
                ...state,
                novedadEdit: { ...action.payload }
            }

        case types.novedadesDelete:
                return {
                    ...state,
                    novedades: state.novedades.filter(
                        u => ( u.id !== action.payload )
                    )
                }

        case types.novedadesUpdate:
            return {
                ...state,
                novedades: state.novedades.map(
                    u => ( u.id === action.payload.id ) ? action.payload : u
                )
            }

        default:
            return state;
    }


}