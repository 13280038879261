import React, { useState } from 'react';
import Modal from 'react-modal';
import PlusIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';
import SyncIcon from '@material-ui/icons/Sync';
import Swal from 'sweetalert2';
import firebaseApp from '../credentials/firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import { moveArrayItemToNewIndex } from '../helpers';


const storage = getStorage(firebaseApp);

const customStyles = {
    content: {
        right: 'auto',
        bottom: 'auto'
    },
};



export const FileUpload = ({ label, pholder, returnFuncImages, payload }) => {

    Modal.setAppElement('#root');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [cargando, SetCargando] = useState(false);
    const [images, setImages] = useState(payload);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }

    async function handleFileChange(e) {

        const file = e.target.files[0];

        if (file) {
            SetCargando(true);

            var time = new Date().getTime();

            const fileRef = ref(storage, `images/archivo-${time + file.name}`);
            await uploadBytes(fileRef, file);

            const url = await getDownloadURL(fileRef);
            console.log(url);
            setImages([...images, { id: time, url: url }]);
            SetCargando(false);
        }

    }

    const handleGuardar = () => {
        returnFuncImages(images);
        closeModal();
    }

    const removerImg = (id) => {
        Swal.fire({
            title: `Estas seguro de que desea eliminar la imagen?`,
            showCancelButton: true,
            icon: 'warning',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {

                setImages(images.filter(function (i) { return i.id !== id }));
            }
        })
    }

    const moveBefore = (index) => {
        let newArr = moveArrayItemToNewIndex(images, (index - 1), index)
        setImages(newArr);
        returnFuncImages(newArr);
    }

    return (
        <div className="custom-input">
            <p className="label">{label}</p>
            <input
                id="fileSelector"
                type="file"
                name="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />

            <button className="bloque" onClick={openModal}>
                <ImageIcon className="icon" />
                <span>{pholder}</span>
            </button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                className="modal"
                overlayClassName="modal-fondo"
                closeTimeoutMS={200}
            >

                <div className="modal-container">

                    {
                        cargando &&
                        <div className="loader"><SyncIcon className="rotate" /></div>
                    }

                    <CloseIcon className="close" onClick={closeModal} />
                    <div className="header">
                        <ImageIcon /><span>Subir imágenes</span>
                    </div>

                    <div className="img-container">

                        {
                            images.length > 0
                                ?
                                images.map((img, index) => (

                                    <ImgComp key={img.id} img={img} removerImg={removerImg} moveBefore={moveBefore} index={index} />

                                ))
                                :
                                <span className="info">No hay imágenes cargadas</span>
                        }
                    </div>

                    <button className="add" onClick={handlePictureClick}><PlusIcon />Agregar</button>

                    <div className="footer">
                        <button onClick={handleGuardar}>Guardar</button>
                        <button onClick={closeModal} className="cancel">Cancelar</button>
                    </div>
                </div>

            </Modal>

        </div>

    )
}



const ImgComp = ({ img, removerImg, moveBefore, index }) => {

    return (
        <div
            className="image"
            style={{
                backgroundImage: `url(${(img.url)})`
            }}>
            <RemoveIcon className="remove" onClick={() => removerImg(img.id)} />
            {index > 0 && <ArrowLeft className="arrowLeft" onClick={() => moveBefore(index)} />}
        </div>
    )

}

