import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { faqsStartAddNew, faqsStartEdit } from '../actions/faqs';
import { useParams } from 'react-router';

export const FaqsForm = () => {


    const { id } = useParams();

    const dispatch = useDispatch();
    const { faqEdit } = useSelector( state => state.faqs );

    let titulo;
    let bajada;
    let iniState;


    if ( !id ) {
        titulo = 'Crear pregunta frecuente';
        bajada = 'Complete el siguiente formulario con los datos requeridos.';
        iniState = {
            pregunta: '',
            respuesta: ''
        };

    }else{
        titulo = 'Editar pregunta frecuente';
        bajada = 'Modifique los campos que quiera editar.';
        iniState = faqEdit;
    }

    const initialState = iniState;
    const [ formValues, setFormValues ] = useState( initialState );

    const { pregunta, respuesta } = formValues;

    const handleInputChange = ({ target }) => {

        setFormValues({
            ...formValues,
            [ target.name ]: target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleGuardar = () => {

        if ( pregunta.trim() === '' || respuesta.trim() === '' ) {
            Swal.fire({
                icon: 'error',
                title: 'Los campos son obligatorios'
            })
        } else {
            if ( !id ) {
                dispatch( faqsStartAddNew( formValues ) );
           }else{
                dispatch( faqsStartEdit( formValues ) );
           }
        }

    }

    return (
        <section className="faqs__wrapper">

            <div className="row">
                <h2 className="title">{ titulo }</h2>
                <Link to="/faqs" className="button-volver"><ArrowBackIcon /> Volver al listado de preguntas</Link>
            </div>
            <p className="bajada">{ bajada }</p>

            <form className="input-form" onSubmit={ handleSubmit }>

                <span className="input-label">Pregunta frecuente</span>
                <div className="input simple">
                    <input
                        placeholder="Introduzca aquí la pregunta frecuente. Ej.: ¿Las máquinas publicadas son argentinas?"
                        name="pregunta"
                        value={ pregunta }
                        onChange={ handleInputChange }
                        autoComplete="off"
                    />
                </div>

                <span className="input-label">Respuesta a la pregunta frecuente</span>
                <div className="editor">
                <CKEditor
                    editor={ ClassicEditor }
                    name="respuesta"
                    data={ respuesta }
                    onChange={(event, editor) => {
                        const data = editor.getData()
                        setFormValues({
                            ...formValues,
                            respuesta: data })
                    }}
                />
                </div>

                <button onClick={ handleGuardar }
                    className="form-add"
                ><SaveIcon />Guardar pregunta frecuente</button>
            </form>

        </section>
    )

}