import React, { useState } from 'react';
import Modal from 'react-modal';
import PlusIcon from '@material-ui/icons/ControlPoint';
import CloseIcon from '@material-ui/icons/Close';

const customStyles = {
    content: {
      right: 'auto',
      bottom: 'auto'
    },
  };

export const FeaturesModal = ({ handleAdd }) => {

    const initialState = { id: '', carac: '', valor: '' };
    const [ formValues, setFormValues ] = useState( initialState );
    const { carac, valor } = formValues;

    function openModal() {
    setIsOpen(true);
    }

    function closeModal() {
    setIsOpen(false);
    }

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [ target.name ]: target.value
        })
    }


    const handleSubmit = ( e ) => {
        e.preventDefault();
    }


    const handleGuardar = () => {

        if ( carac && carac.trim() !== '' && valor && valor.trim() !== '') {
        const f = { id:+ new Date(), carac, valor }
        handleAdd( f );
    }

    setFormValues( initialState );
    closeModal();
    }


    Modal.setAppElement('#root');
    const [modalIsOpen, setIsOpen] = useState(false);


    return (
        <div className="simple-row complete">
            <button className="add-row" onClick={ openModal }>
                <PlusIcon />Agregar característica / valor
            </button>

            <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className="modal"
        overlayClassName="modal-fondo"
        closeTimeoutMS={ 200 }
        >

        <div className="modal-container">
          
          <CloseIcon className="close" onClick={ closeModal } />
          <div className="header">
              <span>Agregar feature</span>
          </div>
          
          <div className="img-container">
          
            <form onSubmit={ handleSubmit }>
                <input name="carac"
                        value={ carac }
                        onChange={ handleInputChange }
                        placeholder="Carcaterística"
                        autoComplete="off"
                        className="input-line"    />
                <input name="valor"
                        value={ valor }
                        onChange={ handleInputChange }
                        placeholder="valor"
                        autoComplete="off"
                        className="input-line"    />
            </form>
            </div>

            <div className="footer">
                <button onClick={ handleGuardar } >Guardar</button>
                <button onClick={ closeModal } className="cancel">Cancelar</button>
            </div>
        </div>


        </Modal>
        </div>

    )
}
