import Swal from 'sweetalert2';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';

export const categoriasStartAddNew = ( categoria ) => {

    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'category/new', categoria, 'POST' );
            const body = await resp.json();
    
            if( body.ok ) {
                Swal.fire({
                    title: `Categoria cargada`,
                    icon: 'success'
                })
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

export const setCategoriaEdit = ( categoria ) => ({
    type: types.categoriaEdit,
    payload: categoria
})


export const categoriasStartEdit = ( categoria ) => {

    return async( dispatch ) => {

    try {

        const resp = await fetchSinToken( `category/${ categoria.id }`, categoria, 'PUT' );
        const body = await resp.json();

        if( body.ok ) {
            Swal.fire({
                title: `El registro fue actualizado`,
                icon: 'success'
            })

            dispatch( categoriasUpdate( body.categoria ) )
        }else{
            Swal.fire('Error', body.msg, 'error');
        }


    } catch (error) {
        console.log( error );
        Swal.fire('Error', 'Error en la DB', 'error');
    }

    }
}

export const categoriasUpdate = ( categoria ) => ({
        type: types.categoriasUpdate,
        payload: categoria
})


export const categoriasStartLoading = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'category' );
            const body = await resp.json();
    
            if( body.ok ) {
                dispatch( categoriasLoaded( body.categorias ) )
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }
    }
}

const categoriasLoaded = ( categorias ) => ({
    type: types.categoriasLoaded,
    payload: categorias
})


export const categoriasStartDelete = ( id ) => {

    return async( dispatch ) => {
        try {

            const resp = await fetchSinToken( `category/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire({
                    title: `El registro fue eliminado`,
                    icon: 'success'
                })
                
               dispatch( categoriasDelete( id ) )
            }else{
                Swal.fire('Error', body.msg, 'error');
            }


        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

const categoriasDelete = ( id ) => ({
    type: types.categoriasDelete,
    payload: id
})