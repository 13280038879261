import React from 'react';
import { useForm } from '../hooks/useForm';
import { useDispatch } from 'react-redux';
import { startLogin } from '../actions/auth';

export const LoginScreen = () => {

    const dispatch = useDispatch();
    
    const [ formValues, handleInputChange ] = useForm({
        user: '',
        password: ''
    });

    const { user, password } = formValues;


    const handleLogin = ( e ) => {
        e.preventDefault();

        dispatch( startLogin( user, password ) );
    }
    

    return (
        <div className="background" style={{
            backgroundImage: (`url("/assets/images/background_app.jpg")` )
        }}>

        <div className="login__container">
            <form  className="login__form" onSubmit={ handleLogin }>

                <img className="login__logo" src="/assets/images/logo-blanco.svg" alt="logo" />

                <h2 className="lighter" >Iniciar sesión</h2>
                <h2>Panel de administrador</h2>


                <input 
                        className="auth__input"
                        type="text"
                        name="user"
                        placeholder="Usuario"
                        autoComplete="off"
                        value={ user }
                        onChange={ handleInputChange }
                    />
                    <input 
                        className="auth__input"
                        type="password"
                        name="password"
                        placeholder="Contraseña"
                        value={ password }
                        onChange={ handleInputChange }
                    />

                <p className="pointer">¿Olvidó su contraseña?</p>

                <button className="pointer" type="submit">Iniciar Sesión</button>

            </form>
        </div>

        </div>
    )
}