export const types = {
    authChecking: '[auth] Checking login state',
    authCheckingFinish: '[auth] Finish checking login state',
    authStartTokenRenew: '[auth] Start token renew',

    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authLogout: '[auth] Logout',

    usuariosStartAddNew: '[user] Start Add New',
    usuariosLimpiarForm: '[user] Limpiar Form',
    usuariosStartLoading: '[user] Usuarios Start Loading',
    usuariosLoaded: '[user] Usuarios Loaded',
    usuarioEdit: '[user] Usuario Edit',
    usuariosDelete: '[user] Usuario Deleted',
    usuariosUpdate: '[user] Actualizar usuarios',

    categoriasStartAddNew: '[category] Start Add New',
    categoriasLimpiarForm: '[category] Limpiar Form',
    categoriasStartLoading: '[category] Categorias Start Loading',
    categoriasLoaded: '[category] Categorias Loaded',
    categoriaEdit: '[category] Categoria Edit',
    categoriasDelete: '[category] Categoria Deleted',
    categoriasUpdate: '[category] Actualizar categorias',

    slidersStartAddNew: '[slider] Start Add New',
    slidersLimpiarForm: '[slider] Limpiar Form',
    slidersStartLoading: '[slider] Sliders Start Loading',
    slidersLoaded: '[slider] Sliders Loaded',
    sliderEdit: '[slider] Slider Edit',
    slidersDelete: '[slider] Slider Deleted',
    slidersUpdate: '[slider] Actualizar sliders',

    faqsStartAddNew: '[faq] Start Add New',
    faqsLimpiarForm: '[faq] Limpiar Form',
    faqsStartLoading: '[faq] Faqs Start Loading',
    faqsLoaded: '[faq] Faqs Loaded',
    faqEdit: '[faq] Faq Edit',
    faqsDelete: '[faq] Faq Deleted',
    faqsUpdate: '[faq] Actualizar faqs',

    novedadesStartAddNew: '[news] Start Add New',
    novedadesLimpiarForm: '[news] Limpiar Form',
    novedadesStartLoading: '[news] Novedades Start Loading',
    novedadesLoaded: '[news] Novedades Loaded',
    novedadEdit: '[news] Novedad Edit',
    novedadesDelete: '[news] Novedad Deleted',
    novedadesUpdate: '[news] Actualizar novedades',

    productosStartAddNew: '[news] Start Add New',
    productosLimpiarForm: '[news] Limpiar Form',
    productosStartLoading: '[news] Productos Start Loading',
    productosLoaded: '[news] Productos Loaded',
    productoEdit: '[news] Producto Edit',
    productosDelete: '[news] Producto Deleted',
    productosUpdate: '[news] Actualizar productos',
    
}