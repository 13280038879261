import { types } from "../types/types";

const initialState = {
    productos: [],
    productoEdit: {}
}

export const productosReducer = ( state=initialState, action ) => {

    switch ( action.type ) {

        case types.productosLoaded:
            return {
                ...state,
                productos: [ ...action.payload ]
            }

        case types.productoEdit:
            return {
                ...state,
                productoEdit: { ...action.payload }
            }

        case types.productosDelete:
                return {
                    ...state,
                    productos: state.productos.filter(
                        u => ( u.id !== action.payload )
                    )
                }

        case types.productosUpdate:
            return {
                ...state,
                productos: state.productos.map(
                    u => ( u.id === action.payload.id ) ? action.payload : u
                )
            }

        default:
            return state;
    }


}