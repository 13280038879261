import React from 'react';
import { Provider } from 'react-redux';
import { AppRouter } from './routers/AppRouter';
import { store } from './store/store';

export const AdminApp = () => {

    // const init = () => {
    //     return JSON.parse( localStorage.getItem('user') ) || { logged: false };
    // }

    return (
        <Provider store={ store }>
            <AppRouter />
        </Provider>
    )
}
