import { types } from "../types/types";

const initialState = {
    usuarios: [],
    usuarioEdit: {}
}

export const usuariosReducer = ( state=initialState, action ) => {

    switch ( action.type ) {

        case types.authLogout:
            return {
                limpiarForm: true
            }

        case types.usuariosLoaded:
            return {
                ...state,
                usuarios: [ ...action.payload ]
            }

        case types.usuarioEdit:
            return {
                ...state,
                usuarioEdit: { ...action.payload, password: ''}
            }

        case types.usuariosDelete:
                return {
                    ...state,
                    usuarios: state.usuarios.filter(
                        u => ( u.id !== action.payload )
                    )
                }

        case types.usuariosUpdate:
            return {
                ...state,
                usuarios: state.usuarios.map(
                    u => ( u.id === action.payload.id ) ? action.payload : u
                )
            }

        default:
            return state;
    }


}