import { types } from "../types/types";

const initialState = {
    sliders: [],
    sliderEdit: {}
}

export const slidersReducer = ( state=initialState, action ) => {

    switch ( action.type ) {

        case types.slidersLoaded:
            return {
                ...state,
                sliders: [ ...action.payload ]
            }

        case types.sliderEdit:
            return {
                ...state,
                sliderEdit: { ...action.payload }
            }

        case types.slidersDelete:
                return {
                    ...state,
                    sliders: state.sliders.filter(
                        u => ( u.id !== action.payload )
                    )
                }

        case types.slidersUpdate:
            return {
                ...state,
                sliders: state.sliders.map(
                    u => ( u.id === action.payload.id ) ? action.payload : u
                )
            }

        default:
            return state;
    }


}