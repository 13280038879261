import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import { ImageInput, LinkInput } from '../components/Widgets'
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { slidersStartAddNew, slidersStartEdit } from '../actions/sliders';
import { useParams } from 'react-router';


export const SliderForm = () => {


    const { id } = useParams();

    const dispatch = useDispatch();
    const { sliderEdit } = useSelector( state => state.sliders );

    let title;
    let desc;
    let iniState;

    if ( !id ) {
        title = 'Crear slide';
        desc = 'Complete el siguiente formulario con los datos requeridos.';
        iniState = {
            titulo: '',
            bajada: '',
            link: '',
            imagen: ''
        };

    }else{
        title = 'Editar slide';
        desc = 'Modifique los campos que quiera editar.';
        iniState = sliderEdit;
    }

    const initialState = iniState;
    const [ formValues, setFormValues ] = useState( initialState );


    const { titulo, bajada, link } = formValues;

    const handleSubmit = (e) => {
        e.preventDefault();
    }
    
    const handleGuardar = () => {
        if ( titulo.trim() === '' ) {
            Swal.fire({
                icon: 'error',
                title: 'El título es obligatorio'
            })
        } else {
            if ( !id ) {
                dispatch( slidersStartAddNew( formValues ) );
           }else{
                dispatch( slidersStartEdit( formValues ) );
           }
        }

    }



    const returnFuncOneImage = ( imagen ) => {
        setFormValues({  ...formValues, imagen  });
    }

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [ target.name ]: target.value
        })
    }


    return (
        <section className="slider__wrapper">

            <div className="row">
                <h2 className="title">{ title }</h2>
                <Link to="/slider" className="button-volver"><ArrowBackIcon /> Volver al listado de slides</Link>
            </div>
            <p className="bajada">{ desc }</p>


            <form className="input-form" onSubmit={ handleSubmit }>

                <span className="input-label mtb-0">Título de la slide</span>
                <div className="input triple">
                    <input
                        placeholder="Introduzca aquí el título"
                        name="titulo"
                        value={ titulo }
                        onChange={ handleInputChange }
                        autoComplete="off"

                    />

                    <ImageInput
                        label="Imagen"
                        pholder="Subir imagen"
                        returnFuncOneImage={ returnFuncOneImage }
                     />

                    <LinkInput
                        label='Botón "Ver más"'
                        pholder="Ingresar link"
                        name="link"
                        payload={ link }
                        handleInputChange={ handleInputChange }/>

                </div>

                <span className="input-label">Bajada de texto corto</span>
                <div>
                <textarea
                    name="bajada"
                    type='text'
                    value={ bajada }
                    onChange={ handleInputChange }
                >
                </textarea>
                </div>

                <button onClick={ handleGuardar }
                    className="form-add"
                ><SaveIcon />Guardar</button>
            </form>

        </section>
    )

}