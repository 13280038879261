import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { slidersStartLoading } from '../actions/sliders';
import { SliderRow } from './TableRow';
import SearchIcon from '@material-ui/icons/Search';

export const SliderScreen = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( slidersStartLoading() )
        
    }, [ dispatch ])

    const { sliders } = useSelector( state => state.sliders );

    const total = sliders ? sliders.length : 0;
    const totalActivos = sliders ? sliders.filter( s =>  s.activo ).length : 0;

    const [ search, SetSearch ] = useState('');
    const [ filteredRes, setFilteredRes ] = useState([ ]);

    const handleSearch = ({ target }) => {
        SetSearch( target.value )
        triggerSearch();
    }

    const triggerSearch = () => {
        let filteredBySearch = sliders.filter(s => s.titulo.toLowerCase().includes(search.toLowerCase()))
        setFilteredRes( filteredBySearch )
    }

    useEffect(() => {
        setFilteredRes( sliders );
    }, [ sliders ])


    return (
        <section className="slider__wrapper">

            <h2 className="title">Slider home</h2>
            <p className="bajada">Desde este panel se puede añadir, editar, eliminar, activar e inactivar slides.</p>

            <div className="row">

                <div style={{ display: 'flex' }}>
                    <div className="info-button">
                        <span>{ total }</span> Slides creadas
                    </div>
                    <div className="info-button">
                        <span>{ totalActivos }</span> Slides activas
                    </div>
                </div>

                <Link className="active-button" to="/slider/add">
                    <AddIcon />
                    Crear una nueva slide
                </Link>

            </div>

            <div className="filter">
                <input 
                    className="nav-desktop-search-input"
                    placeholder="Buscar"
                    type="text"
                    name="search"
                    value={ search }
                    onChange={ handleSearch }
                    autoComplete="off"
                />
            <SearchIcon className="icon" onClick={ () => triggerSearch } />
            </div>

            <div className="table slider">
                <div className="table-header">
                    SLIDE
                </div>
                <div className="table-header actions">
                    ACCIONES
                </div>

                <div className="row table-header-actions">
                    <span>activo</span>
                    <span>editar</span>
                    <span>eliminar</span>
                </div>

                {
                        filteredRes.map(
                            s => ( <SliderRow
                                key={ s.id }
                                slider={ s }
                            /> )
                        )
                    }
            </div>
            
        </section>
    )
}
