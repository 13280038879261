import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { novedadesStartLoading } from '../actions/novedades';
import { NovedadRow } from './TableRow';
import SearchIcon from '@material-ui/icons/Search';

export const NovedadesScreen = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( novedadesStartLoading() )
        
    }, [ dispatch ])

    const { novedades } = useSelector( state => state.novedades );

    const total = novedades ? novedades.length : 0;
    const totalActivos = novedades ? novedades.filter( n =>  n.activo ).length : 0;

    const [ search, SetSearch ] = useState('');
    const [ filteredRes, setFilteredRes ] = useState([ ]);

    const handleSearch = ({ target }) => {
        SetSearch( target.value )
        triggerSearch();
    }

    const triggerSearch = () => {
        let filteredBySearch = novedades.filter(n => n.titulo.toLowerCase().includes(search.toLowerCase()))
        setFilteredRes( filteredBySearch )
    }

    useEffect(() => {
        setFilteredRes( novedades );
    }, [ novedades ])

    return (
        <section className="novedades__wrapper">

            <h2 className="title">Novedades</h2>
            <p className="bajada">Desde este panel se puede añadir, editar, eliminar, activar e inactivar las novedades.</p>

            <div className="row">

                <div style={{ display: 'flex' }}>
                    <div className="info-button">
                        <span>{ total }</span> Novedades creadas
                    </div>
                    <div className="info-button">
                        <span>{ totalActivos }</span> Novedades activas
                    </div>
                </div>

                <Link className="active-button" to="/novedades/add">
                    <AddIcon />
                    Crear una nueva novedad
                </Link>

            </div>

            <div className="filter">
                <input 
                    className="nav-desktop-search-input"
                    placeholder="Buscar"
                    type="text"
                    name="search"
                    value={ search }
                    onChange={ handleSearch }
                    autoComplete="off"
                />
            <SearchIcon className="icon" onClick={ () => triggerSearch } />
            </div>

            <div className="table novedades">
                <div className="table-header">
                    NOVEDAD
                </div>
                <div className="table-header actions">
                    ACCIONES
                </div>

                <div className="row table-header-actions">
                    <span>activo</span>
                    <span>editar</span>
                    <span>eliminar</span>
                </div>

                {
                    filteredRes.map(
                        n => ( <NovedadRow
                            key={ n.id }
                            novedad={ n }
                        /> )
                    )
                }


            </div>
            
        </section>
    )
}
