import Swal from 'sweetalert2';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';

export const novedadesStartAddNew = ( novedad ) => {

    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'novedad/new', novedad, 'POST' );
            const body = await resp.json();
    
            if( body.ok ) {
                Swal.fire({
                    title: `Novedad cargada`,
                    icon: 'success'
                })
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

export const setNovedadEdit = ( novedad ) => ({
    type: types.novedadEdit,
    payload: novedad
})


export const novedadesStartEdit = ( novedad ) => {

    return async( dispatch ) => {

    try {

        const resp = await fetchSinToken( `novedad/${ novedad.id }`, novedad, 'PUT' );
        const body = await resp.json();

        if( body.ok ) {
            Swal.fire({
                title: `El registro fue actualizado`,
                icon: 'success'
            })

            dispatch( novedadesUpdate( body.novedad ) )
        }else{
            Swal.fire('Error', body.msg, 'error');
        }


    } catch (error) {
        console.log( error );
        Swal.fire('Error', 'Error en la DB', 'error');
    }

    }
}

export const novedadesUpdate = ( novedad ) => ({
        type: types.novedadesUpdate,
        payload: novedad
})


export const novedadesStartLoading = () => {

    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'novedad' );
            const body = await resp.json();
    
            if( body.ok ) {
                dispatch( novedadesLoaded( body.novedades ) )
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }
    }
}

const novedadesLoaded = ( novedades ) => ({
    type: types.novedadesLoaded,
    payload: novedades
})


export const novedadesStartDelete = ( id ) => {

    return async( dispatch ) => {
        try {

            const resp = await fetchSinToken( `novedad/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire({
                    title: `El registro fue eliminado`,
                    icon: 'success'
                })
                
               dispatch( novedadesDelete( id ) )
            }else{
                Swal.fire('Error', body.msg, 'error');
            }


        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

const novedadesDelete = ( id ) => ({
    type: types.novedadesDelete,
    payload: id
})