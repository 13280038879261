import { types } from "../types/types";

const initialState = {
    logged: false,
    // uid: null,
    // user: null
}

export const authReducer = ( state=initialState, action ) => {

    switch ( action.type ) {
        case types.authLogin:
            return {
                ...state,
                ...action.payload,
                logged: true           
            }

        case types.authLogout:
            return {
                logged: false
            }

        default:
            return state;
    }


}