import React from 'react';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import Swal from 'sweetalert2';
import { TableRowModal } from './TableRowModal';

export const CategoriasSimpleTable = ({ titulo, payload, removerGrupo, addGrupo }) => {


    const handleRemove = ( id ) => {
        Swal.fire({
            title: `Estas seguro de que desea remover el grupo?`,
            showCancelButton: true,
            icon: 'warning',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Remover',
          }).then((result) => {
            if (result.isConfirmed) {
                
                removerGrupo( id );
                
            }
          })
    }

    return (
        <div className="simple-table mtb-0">
        <div className="simple-row header complete">
            { titulo }
        </div>

        {
            payload.map(
            p => ( 
                <div className="simple-row complete" key={ p._id }>
                   { p.titulo }    <span onClick={ () => handleRemove( p._id ) }><RemoveIcon /></span>
                </div>
             )
            )
        }
        

        <div className="simple-row complete">

            <TableRowModal addGrupo={ addGrupo } />

        </div>

        
    </div>
    )
}
