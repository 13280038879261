import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { PriceInput, MultipleImagesInput, LinkInput, PdfInput } from '../components/Widgets';
import { useParams } from 'react-router';
import { RelatedProducts } from './RelatedProducts';
import { FeaturesSimpleTable } from './FeaturesSimpleTable';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { productosStartAddNew, productosStartEdit } from '../actions/productos';

export const ProductosForm = () => {

    const { id } = useParams();
    const dispatch = useDispatch();

    let title;
    let bajada;
    let iniState;

    const { categorias } = useSelector( state => state.categorias );
    const { productoEdit } = useSelector( state => state.productos );

    if ( !id ) {
        title = 'Crear producto';
        bajada = 'Complete el siguiente formulario con los datos requeridos.';

        iniState = {
            related: [],
            images: [],
            video: '',
            features: [],
            titulo: '',
            parentGroup: '',
            parentCategory: '',
            descripcionShort: '',
            descripcionLong: '',
            precioPesos: '',
            precioDolar: '',
            disponible: true,
            fabricado:  true,
            nuevo:  true,
            destacado: false,
            pdfFile: '',
        }

    }else{
        title = 'Editar producto';
        bajada = 'Modifique los campos que quiera editar.';
        iniState = productoEdit;

        const { features, images } = iniState;
        
        for ( let i=0; i<features.length; i++ ) {
            features[i].id = features[i]._id;
        }

        for ( let i=0; i<images.length; i++ ) {
            images[i].id = images[i]._id;
        }

    }

    const initialState = iniState;
    const [ formValues, setFormValues ] = useState( initialState );
    const {
        related,
        images,
        video,
        features,
        titulo,
        parentGroup,
        parentCategory,
        descripcionShort,
        descripcionLong,
        precioPesos,
        precioDolar,
        disponible,
        fabricado,
        destacado,
        nuevo } = formValues;

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [ target.name ]: target.value
        })
    }
    
    const catActual = categorias.filter( function( c ) { return c.id === parentCategory } )
    const catInicial = id ? catActual[0].titulo : '';

    const [ categoryListVisible, setCategoryListVisible ] = useState( false );
    const [ category, setCategory ] = useState ( catInicial );

    const gruposInicial = catInicial !== '' ? true : false;
    const emptyArray = [];
    const gruposSetInicial = catInicial !== '' ? catActual[0].grupos : emptyArray ;

    const [ gruposVisible, setGruposVisible ] = useState( gruposInicial );
    const [ gruposListVisible, setGruposListVisible ] = useState( false );
    const [ grupo, setGrupo ] = useState( parentGroup );
    const [ filterGroup, setFilterGroup ] = useState( gruposSetInicial );

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const toggleCategoryList = () => {
        setCategoryListVisible( !categoryListVisible );
        setGruposListVisible( false );
    }

    const toggleGruposList = () => {
        setGruposListVisible( !gruposListVisible );
        setCategoryListVisible( false );
    }

    const selectCategory = ( cat ) => {
        setCategory( cat.titulo );
        setCategoryListVisible( false );

        setFormValues({ ...formValues, parentCategory: cat.id, parentGroup: '' })
        setGrupo( '' );
        setFilterGroup( cat.grupos );
        setGruposVisible( true );
    }

    const selectGrupo = ( grupo ) => {
        setGrupo( grupo.titulo );
        setFormValues({ ...formValues, parentGroup: grupo.titulo })
        setGruposListVisible( false );
    }

    const returnFuncImages = ( images ) => {
        setFormValues({  ...formValues, images  });
    }

    const returnPdfFile = ( pdfFile ) => {
        setFormValues({ ...formValues, pdfFile })
    }

    const retrunFeatures = ( features ) => {
        setFormValues({ ...formValues, features })
    }

    const returnRelated = ( related ) => {
        setFormValues({  ...formValues, related  });
    }

    
    const handleGuardar = () => {

    if ( titulo.trim() === '' ) {
        Swal.fire({
            icon: 'error',
            title: 'El título es obligatorio'
        })
        } else {

        if ( !id ) {
                dispatch( productosStartAddNew( formValues ) );
        }else{
            dispatch( productosStartEdit( formValues ) );
        }
    }
    
    }

        return (
            <section className="productos__wrapper">
    
                <div className="row">
                    <h2 className="title">{ title }</h2>
                    <Link to="/productos" className="button-volver"><ArrowBackIcon /> Volver al listado de productos</Link>
                </div>
                <p className="bajada">{ bajada }</p>
    
            <form className="input-form" onSubmit={ handleSubmit }>

                <span className="input-label mtb-0"  
                >Nombre de la maquinaria</span>
                <div className="input triple">
                    <input
                        placeholder="Introduzca aquí el nombre del producto"
                        name="titulo"
                        onChange={ handleInputChange }
                        value={ titulo }
                        autoComplete="off"
                    />

                    <PriceInput
                        currency="dolar"
                        label="Precio en dólares"
                        pholder="Ingrese valor"
                        name="precioDolar"
                        payload={ precioDolar }
                        handleInputChange={ handleInputChange }
                    />

                    <PriceInput
                        currency="peso"
                        label="Precio en pesos"
                        pholder="Ingrese valor"
                        name="precioPesos"
                        payload={ precioPesos }
                        handleInputChange={ handleInputChange }
                    />

                </div>

                <div className="table cat">
                    <div className="table-row"><span className="label-cat">Categoría:</span>
                        <span className="desplegable" onClick={ toggleCategoryList }>

                        { category === '' ?
                        'Seleccionar categoría' 
                        :                        
                        category
                        }
                        {
                          categoryListVisible ?
                            <KeyboardArrowUpIcon className="chevron"/>
                            :
                            <KeyboardArrowDownIcon className="chevron"/>
                        }
                        
                        </span>

                        <ul className=
                        {
                            categoryListVisible ?
                            "lista-desplegable visible"
                            :
                            "lista-desplegable"
                        }
                        >
                            {
                                categorias.map( c => (
                                    <li
                                    key={ c.id }
                                    className="selectRow"
                                    onClick={ () => selectCategory( c ) }
                                    >
                                        { c.titulo }
                                    </li>
                                ))

                            }

                        </ul>

                    </div>

                    <div className="table-row end">
                        <Link className="edit" to="/productos/categorias">
                            <EditIcon />Editar categorías
                        </Link>
                    </div>
                                        
                { gruposVisible &&
                (
                    <>
                    <div className="table-row"><span className="label-cat">Grupo:</span>
                        <span className="desplegable" onClick={ toggleGruposList }>

                        { grupo === '' ?
                        'Seleccionar grupo de producto' 
                        :                        
                        grupo
                        }
                        {
                          gruposListVisible ?
                            <KeyboardArrowUpIcon className="chevron"/>
                            :
                            <KeyboardArrowDownIcon className="chevron"/>
                        }
                        </span>

                        <ul className=
                        {
                            gruposListVisible ?
                            "lista-desplegable visible"
                            :
                            "lista-desplegable"
                        }
                        >

                            {
                                filterGroup.map( g => (
                                    <li className="selectRow"
                                    key={ g.id }
                                    onClick={ () => selectGrupo( g ) }>
                                       { g.titulo }
                                    </li>
                                ))

                            }
                        </ul>

                    </div>
                    <div className="table-row end"></div>
                </>
            )
                    }
            </div>

                <div className="input triple">
                    <RelatedProducts
                        related={ related }
                        returnRelated={ returnRelated }
                    />

                    <MultipleImagesInput
                        label="Imágenes"
                        pholder="Subir imágenes"
                        payload={ images }
                        returnFuncImages={ returnFuncImages }
                     />

                    <LinkInput
                        label="Video"
                        pholder="Ingresar link"
                        name="video"
                        payload={ video }
                        handleInputChange={ handleInputChange }/>
                        
                </div>

                <div className="input triple center">

                    <div className="check-set">
                            Fabricado
                            <span onClick={ () =>
                            setFormValues({ ...formValues, fabricado: !fabricado })
                            }>
                               {
                                   fabricado ?
                                    <CheckBoxOutlinedIcon />
                                   :
                                    <CheckBoxOutlineBlankOutlinedIcon />
                                    
                               } 
                            </span>

                        </div>

                        <div className="check-set">
                            Disponible
                            <span onClick={ () =>
                                setFormValues({ ...formValues, disponible: !disponible })
                            }>
                               {                                   
                                   disponible ?
                                   <CheckBoxOutlinedIcon />
                                   :
                                    <CheckBoxOutlineBlankOutlinedIcon />
                               } 
                            </span>

                    </div>
                    <div className="check-set">
                            Nuevo
                            <span onClick={ () =>
                                setFormValues({ ...formValues, nuevo: !nuevo })
                            }>
                               {
                                   nuevo ?
                                   <CheckBoxOutlinedIcon />
                                   :
                                    <CheckBoxOutlineBlankOutlinedIcon />
                               } 
                            </span>

                        </div>
                        <div className="check-set">
                            Destacado
                            <span onClick={ () =>
                                setFormValues({ ...formValues, destacado: !destacado })
                            }>
                               {
                                   destacado ?
                                   <CheckBoxOutlinedIcon />
                                   :
                                    <CheckBoxOutlineBlankOutlinedIcon />
                               } 
                            </span>

                        </div>
                </div>

                <span className="input-label">Bajada de texto corto</span>
                <div>
                <textarea
                    name="descripcionShort"
                    value={ descripcionShort }
                    onChange={ handleInputChange }
                >
                </textarea>
                </div>
                
                <span className="input-label">Descripción de texto largo</span>
                <div className="editor">
                <CKEditor
                    editor={ ClassicEditor }
                    name="descripcionLong"
                    data={ descripcionLong }
                    onChange={(event, editor) => {
                        const data = editor.getData()
                        setFormValues({
                            ...formValues,
                            descripcionLong: data })
                    }}
                />
                </div>

                <PdfInput returnPdfFile={ returnPdfFile } />

                <FeaturesSimpleTable features={ features } returnFeatures={ retrunFeatures }/>


                <button  onClick={ handleGuardar }
                    className="form-add mt-40"
                ><SaveIcon />Guardar Producto</button>
            </form>


        </section>
    )

}



