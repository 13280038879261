import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import { ImageInput, DateInput, VistasInput } from '../components/Widgets'
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { novedadesStartAddNew, novedadesStartEdit } from '../actions/novedades';
import { useParams } from 'react-router';

export const NovedadesForm = () => {

    const { id } = useParams();

    const dispatch = useDispatch();
    const { novedadEdit } = useSelector( state => state.novedades );

    let title;
    let desc;
    let iniState;

    if ( !id ) {
        title = 'Crear una novedad';
        desc = 'Complete el siguiente formulario con los datos requeridos.';
        iniState = {
            titulo: '',
            bajada: '',
            imagen: '',
            fecha: '',
            descripcion: '',
            vistas: 0
        };

    }else{
        title = 'Editar una novedad';
        desc = 'Modifique los campos que quiera editar.';
        iniState = novedadEdit;
    }

    const initialState = iniState;
    const [ formValues, setFormValues ] = useState( initialState );

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const { titulo, bajada, fecha, descripcion, vistas } = formValues;

    const returnFuncOneImage = ( imagen ) => {
        setFormValues({  ...formValues, imagen  });
    }

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [ target.name ]: target.value
        })
    }

    const handleGuardar = () => {

        if ( titulo.trim() === '' ) {
            Swal.fire({
                icon: 'error',
                title: 'El título es obligatorio'
            })
        } else {

        if ( !id ) {
            dispatch( novedadesStartAddNew( formValues ) );
       }else{
            dispatch( novedadesStartEdit( formValues ) );
       }
    }
    }


    return (
        <section className="novedades__wrapper">

            <div className="row">
                <h2 className="title">{ title }</h2>
                <Link to="/novedades" className="button-volver"><ArrowBackIcon /> Volver al listado de novedades</Link>
            </div>
            <p className="bajada">{ desc }</p>

            <form className="input-form" onSubmit={ handleSubmit }>

                <span className="input-label mtb-0">Título de la novedad</span>
                <div className="input triple">
                    <input
                        placeholder="Introduzca aquí el título de la novedad"
                        name="titulo"
                        value={ titulo }
                        onChange={ handleInputChange }
                        autoComplete="off"
                    />
                    
                    <VistasInput
                        label="Vistas"
                        name="vistas"
                        handleInputChange={ handleInputChange }
                        payload={ vistas }
                        pholder={ 0 }
                    />
                    <ImageInput
                        label="Imagen"
                        pholder="Subir imagen"
                        returnFuncOneImage={ returnFuncOneImage }
                     />
                    
                    <DateInput label="Fecha"
                        pholder="dd/mm/aaaa"
                        handleInputChange={ handleInputChange }
                        name="fecha"
                        payload={ fecha }
                    />

                </div>

                <span className="input-label">Bajada de texto corto</span>
                <div>
                <textarea
                    name="bajada"
                    type='text'
                    value={ bajada }
                    onChange={ handleInputChange }
                >
                </textarea>
                </div>
                
                <span className="input-label">Descripción de texto largo</span>
                <div className="editor">
                <CKEditor
                    editor={ ClassicEditor }
                    name="descripcion"
                    data={ descripcion }
                    onChange={(event, editor) => {
                        const data = editor.getData()
                        setFormValues({
                            ...formValues,
                            descripcion: data })
                    }}
                />
                </div>

                <button onClick={ handleGuardar }
                    className="form-add"
                ><SaveIcon />Guardar novedad</button>
            </form>

        </section>
    )

}