import React from 'react';
import ReactDOM from 'react-dom';
import 'animate.css';
import './styles/styles.scss';
import { AdminApp } from './AdminApp';


ReactDOM.render(
    <AdminApp />,
  document.getElementById('root')
);

