import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { usuariosStartAddNew, usuariosStartEdit } from '../actions/usuarios';
import { useParams } from 'react-router';

export const UsuariosForm = () => {

    const { id } = useParams();

    const dispatch = useDispatch();
    const { usuarioEdit } = useSelector( state => state.usuarios );

    let titulo;
    let bajada;
    let iniState;

    if ( !id ) {
        titulo = 'Crear usuario';
        bajada = 'Complete el siguiente formulario con los datos requeridos.';
        iniState = {
            user: '',
            password: '',
            email: '',
            name: '',
            rol: ''
        };

    }else{
        titulo = 'Editar usuario';
        bajada = 'Modifique los campos que quiera editar.';
        iniState = usuarioEdit;
    }

    const initialState = iniState;
    const [ formValues, setFormValues ] = useState( initialState );
    const { user, password, email, name, rol } = formValues;

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [ target.name ]: target.value
        })
    }

    const handleGuardar = () => {

        if ( !id ) {
            if ( user.trim() === '' || password.trim() === ''|| password.trim() === ''|| password.trim() === '' ) {
                Swal.fire({
                    icon: 'error',
                    title: 'Los campos son obligatorios'
                })
            }else{
                dispatch( usuariosStartAddNew( formValues ) );
            }
            
       }else{
            
        const { user, name, email, password, rol } = formValues;
        const edit = {};

        if( user !== '' ) {
            edit.user = user;
        }
        if( name !== '' ) {
            edit.name = name;
        }
        if( email !== '' ) {
            edit.email = email;
        }
        if( password !== '' ) {
            edit.password = password;
        }
        if( rol !== '' ) {
            edit.rol = rol;
        }

        dispatch( usuariosStartEdit( edit ) );
        }

    }


    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const [ rolListVisible, setRolListVisible ] = useState( false );

    const toggleRolList = () => {
        setRolListVisible( !rolListVisible );
    }

    const selectRol = ( rol ) => {
        setRolListVisible( false );

            setFormValues({
                ...formValues,
                rol: rol.toLowerCase()
            })
        }

        const [ visibility, setVisibility ] = useState( false )

        const handleVisibility = () => {
            setVisibility( !visibility );
        }

    return (
        <section className="usuarios__wrapper">

            <div className="row">
                <h2 className="title">{ titulo }</h2>
                <Link to="/usuarios" className="button-volver"><ArrowBackIcon /> Volver al listado de usuarios</Link>
            </div>
            <p className="bajada">{ bajada }</p>

            <form className="input-form" onSubmit={ handleSubmit }>

                <div className="input">
                    <span>Nombre de usuario </span>
                    <input 
                        placeholder="juanperez"
                        name="user"
                        value={ user }
                        onChange={ handleInputChange }
                        autoComplete="off"
                    />
                </div>

                <div className="input">
                    <span>Contraseña </span>
                    <input
                        placeholder="Mínimo 8 caracteres"
                        name="password"
                        value={ password }
                        type={ visibility ? "text" : "password" }
                        onChange={ handleInputChange }
                        autoComplete="off"
                    />
                    <span className="visibilidad" onClick={ handleVisibility }>
                        { visibility ?
                            <VisibilityOutlinedIcon />
                            :
                            <VisibilityOffOutlinedIcon />
                        }

                    </span>
                    
                </div>
                
                <div className="input">
                    <span>Nombre y apellido </span>
                    <input 
                        placeholder="Juan Perez"
                        name="name"
                        value={ name }
                        onChange={ handleInputChange }
                        autoComplete="off"
                    />
                </div>
                
                <div className="input">
                    <span>Email (opcional) </span>
                    <input 
                        placeholder="email@mail.com"
                        name="email"
                        value={ email }
                        onChange={ handleInputChange }
                        autoComplete="off"
                    />
                </div>

                <div className="input-desplegable">
                    <span className="desplegable" onClick={ toggleRolList }>
                    Seleccionar rol
                    {
                    rolListVisible ?
                        <KeyboardArrowUpIcon className="chevron"/>
                        :
                        <KeyboardArrowDownIcon className="chevron"/>
                    }

                    </span>

                    <span className="rol-selected">{ rol }</span>

                    <ul className=
                    {
                        rolListVisible ?
                        "lista-desplegable visible"
                        :
                        "lista-desplegable"
                    }
                    >
                        <li className="selectRow" onClick={ () => selectRol( 'Admin' ) }>
                            Admin
                        </li>
                        <li className="selectRow" onClick={ () => selectRol( 'Editor' ) }>
                            Editor
                        </li>
                    </ul>

                </div>

                <button  onClick={ handleGuardar }
                    className="form-add mt-40"
                ><SaveIcon />Guardar usuario</button>
            </form>

        </section>
    )
}
