import { types } from "../types/types";

const initialState = {
    faqs: [],
    faqEdit: {}
}

export const faqsReducer = ( state=initialState, action ) => {

    switch ( action.type ) {

        case types.faqsLoaded:
            return {
                ...state,
                faqs: [ ...action.payload ]
            }

        case types.faqEdit:
            return {
                ...state,
                faqEdit: { ...action.payload }
            }

        case types.faqsDelete:
                return {
                    ...state,
                    faqs: state.faqs.filter(
                        u => ( u.id !== action.payload )
                    )
                }

        case types.faqsUpdate:
            return {
                ...state,
                faqs: state.faqs.map(
                    u => ( u.id === action.payload.id ) ? action.payload : u
                )
            }

        default:
            return state;
    }


}