import Swal from 'sweetalert2';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';

export const faqsStartAddNew = ( faq ) => {

    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'faq/new', faq, 'POST' );
            const body = await resp.json();
    
            if( body.ok ) {
                Swal.fire({
                    title: `Faq cargada`,
                    icon: 'success'
                })
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

export const setFaqEdit = ( faq ) => ({
    type: types.faqEdit,
    payload: faq
})


export const faqsStartEdit = ( faq ) => {

    return async( dispatch ) => {

    try {

        const resp = await fetchSinToken( `faq/${ faq.id }`, faq, 'PUT' );
        const body = await resp.json();

        if( body.ok ) {
            Swal.fire({
                title: `El registro fue actualizado`,
                icon: 'success'
            })

            dispatch( faqsUpdate( body.faq ) )
        }else{
            Swal.fire('Error', body.msg, 'error');
        }


    } catch (error) {
        console.log( error );
        Swal.fire('Error', 'Error en la DB', 'error');
    }

    }
}

export const faqsUpdate = ( faq ) => ({
        type: types.faqsUpdate,
        payload: faq
})


export const faqsStartLoading = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'faq' );
            const body = await resp.json();
    
            if( body.ok ) {
                dispatch( faqsLoaded( body.faqs ) )
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }
    }
}

const faqsLoaded = ( faqs ) => ({
    type: types.faqsLoaded,
    payload: faqs
})


export const faqsStartDelete = ( id ) => {

    return async( dispatch ) => {
        try {

            const resp = await fetchSinToken( `faq/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire({
                    title: `El registro fue eliminado`,
                    icon: 'success'
                })
                
               dispatch( faqsDelete( id ) )
            }else{
                Swal.fire('Error', body.msg, 'error');
            }


        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

const faqsDelete = ( id ) => ({
    type: types.faqsDelete,
    payload: id
})