import React, { useState } from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import ImageIcon from '@material-ui/icons/Image';
import firebaseApp from '../credentials/firebase';
// import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// const firestore = getFirestore( firebaseApp );
const storage= getStorage( firebaseApp );

export const OneFileUpload = ({ label, pholder, returnFuncOneImage }) => {

    const [cargando, SetCargando] = useState( false );

    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }

    async function handleFileChange( e ) {

        const file = e.target.files[0];
        
        if( file ) {
            
            SetCargando( true );
            const fileRef = ref( storage, `images/${ file.name }` );
            await uploadBytes( fileRef, file );
            
            const url = await getDownloadURL( fileRef );

            SetCargando( false );
            returnFuncOneImage( url );
         }

    }


   return (
    <div className="custom-input">
        
        {
            cargando &&
            <div className="loader-file"><SyncIcon className="rotate"/></div>
        }
        <p className="label">{ label }</p>
        <input
            id="fileSelector"
            type="file"
            name="file"
            style={{ display: 'none' }}
            onChange={ handleFileChange }
        />

    <button className="bloque" onClick={ handlePictureClick }>
        <ImageIcon className="icon" />
        <span>{ pholder }</span>
    </button>
    </div>

    )
}
