import React from 'react';

export const InicioScreen = () => {
    return (
        <div className="background inicio__background" style={{
            backgroundImage: (`url("/assets/images/background_app.jpg")` ),
        }}>

        <h2>Panel Administrador del sitio web DWM</h2>

        <p>Desde este panel de control de administración se podrá crear y admninistrar contenido del sitio web.</p>
        <p>En el menú lateral izquierdo se encuentran secciones y funcionalidades con las cuales se podrá interactuar.</p>
        <p>Ante cualquier duda contactarse con marketing@dpmsa.com.ar</p>


        </div>
    )
}
