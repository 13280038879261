import React from 'react';
// import { Link } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import MinusIcon from '@material-ui/icons/Remove';
import PlusIcon from '@material-ui/icons/ControlPoint';
import { IconActionSet } from '../components/Widgets';


export const LanguageScreen = () => {
    return (
        <section className="idiomas__wrapper">

            <h2 className="title">Idiomas</h2>
            <p className="bajada">Desde este panel se puede añadir, predeterminar, eliminar, activar e inactivar los idiomas.</p>

            <div className="row">

                <div style={{ display: 'flex' }}>
                    <div className="info-button">
                        <span>3</span> Idiomas cargados
                    </div>
                    <div className="info-button">
                        <span>2</span> Idiomas activos
                    </div>
                    <div className="info-button">
                        Predeterminado: 
                        <div className="info-lang">
                            <div className="flag"                        
                                style={{ backgroundImage: (`url("/assets/images/flags/es-ar.png")`) }}
                            ></div>
                            <div>Español</div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="table lang">
                <div className="table-header">
                    IDIOMA
                </div>
                <div className="table-header">
                    CONFIG. REGIONAL
                </div>
                <div className="table-header">
                    PREDET.
                </div>
                <div className="table-header actions">
                    ACCIONES
                </div>

                <div className="row table-header-actions">    
                    <span>visible</span>
                    <span>editar</span>
                    <span>eliminar</span>
                </div>

                <div className="table-row lang">
                    <div className="flag"                        
                        style={{ backgroundImage: (`url("/assets/images/flags/es-ar.png")`) }}
                    ></div>
                    <div>Español</div>
                </div>

                <div className="table-row">
                    es-ar
                </div>
                <div className="table-row">
                    <CheckIcon />
                </div>
                <div className="table-row">
                    <IconActionSet registro='el idioma' />
                </div>

                <div className="table-row lang">
                    <div className="flag"
                        style={{ backgroundImage: (`url("/assets/images/flags/en-us.png")`) }}
                    ></div>
                    <div>Inglés</div>
                </div>

                <div className="table-row">
                    en-us
                </div>
                <div className="table-row">
                    <MinusIcon />
                </div>
                <div className="table-row">
                    <IconActionSet registro='el idioma' />
                </div>
                
                <div className="table-row lang">
                    <div className="flag"
                        style={{ backgroundImage: (`url("/assets/images/flags/pt-br.png")`) }}
                    ></div>
                    <div>Portugués</div>
                </div>

                <div className="table-row">
                    pt-br
                </div>
                <div className="table-row">
                    <MinusIcon />
                </div>
                <div className="table-row">
                    <IconActionSet registro='el idioma' />
                </div>

                <div className="table-row lang complete">
                    <button className="add-row">
                        <PlusIcon />Agregar idioma
                    </button>
                </div>
            </div>
            
        </section>
    )
}

