import React from 'react';
import {
    Switch,
    Route,
    Redirect
  } from 'react-router-dom';
import { AsideScreen } from '../components/AsideScreen';
import { InicioScreen } from '../components/InicioScreen';
import { UsuariosScreen } from '../components/UsuariosScreen';
import { UsuariosForm } from '../components/UsuariosForm';
import { ProductosScreen } from '../components/ProductosScreen';
import { ProductosForm } from '../components/ProductosForm';
import { ProductosCategorias } from '../components/ProductosCategorias';
import { CategoriasForm } from '../components/CategoriasForm';
import { NovedadesScreen } from '../components/NovedadesScreen';
import { NovedadesForm } from '../components/NovedadesForm';
import { SliderScreen } from '../components/SliderScreen';
import { SliderForm } from '../components/SliderForm';
import { FaqsScreen } from '../components/FaqsScreen';
import { FaqsForm } from '../components/FaqsForm';
import { LanguageScreen } from '../components/LanguageScreen';

export const AdminRoutes = () => {
    return (
    <div className="layout__layout">
        <div className="layout__aside">
            <AsideScreen />
        </div>
        
        <div className="layout__content">

            <Switch>
                <Route exact path="/usuarios" component={ UsuariosScreen } />
                <Route exact path="/usuarios/add" component={ UsuariosForm } />
                <Route exact path="/usuarios/edit/:id" component={ UsuariosForm } />
                <Route exact path="/productos" component={ ProductosScreen } />
                <Route exact path="/productos/add" component={ ProductosForm } />
                <Route exact path="/productos/edit/:id" component={ ProductosForm } />
                <Route exact path="/productos/categorias" component={ ProductosCategorias } />
                <Route exact path="/productos/categorias/add" component={ CategoriasForm } />
                <Route exact path="/productos/categorias/edit/:id" component={ CategoriasForm } />
                <Route exact path="/slider" component={ SliderScreen } />
                <Route exact path="/slider/add" component={ SliderForm } />
                <Route exact path="/slider/edit/:id" component={ SliderForm } />
                <Route exact path="/novedades" component={ NovedadesScreen } />
                <Route exact path="/novedades/add" component={ NovedadesForm } />
                <Route exact path="/novedades/edit/:id" component={ NovedadesForm } />
                <Route exact path="/faqs" component={ FaqsScreen } />
                <Route exact path="/faqs/add" component={ FaqsForm } />
                <Route exact path="/faqs/edit/:id" component={ FaqsForm } />
                <Route exact path="/lang" component={ LanguageScreen } />
                <Route exact path="/" component={ InicioScreen } />

                <Redirect to="/" />
            </Switch>
        </div>
    </div>

    )
}





           
