import Swal from 'sweetalert2';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';

export const productosStartAddNew = ( producto ) => {

    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'product/new', producto, 'POST' );
            const body = await resp.json();
    
            if( body.ok ) {
                Swal.fire({
                    title: `Producto cargado`,
                    icon: 'success'
                })
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

export const setProductoEdit = ( producto ) => ({
    type: types.productoEdit,
    payload: producto
})


export const productosStartEdit = ( producto ) => {

    return async( dispatch ) => {

    try {

        const resp = await fetchSinToken( `product/${ producto.id }`, producto, 'PUT' );
        const body = await resp.json();

        if( body.ok ) {
            Swal.fire({
                title: `El registro fue actualizado`,
                icon: 'success'
            })

            dispatch( productosUpdate( body.producto ) )
        }else{
            Swal.fire('Error', body.msg, 'error');
        }


    } catch (error) {
        console.log( error );
        Swal.fire('Error', 'Error en la DB', 'error');
    }

    }
}

export const productosUpdate = ( producto ) => ({
        type: types.productosUpdate,
        payload: producto
})


export const productosStartLoading = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'product' );
            const body = await resp.json();
    
            if( body.ok ) {
                dispatch( productosLoaded( body.productos ) )
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }
    }
}

const productosLoaded = ( productos ) => ({
    type: types.productosLoaded,
    payload: productos
})


export const productosStartDelete = ( id ) => {

    return async( dispatch ) => {
        try {

            const resp = await fetchSinToken( `product/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire({
                    title: `El registro fue eliminado`,
                    icon: 'success'
                })
                
               dispatch( productosDelete( id ) )
            }else{
                Swal.fire('Error', body.msg, 'error');
            }


        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

const productosDelete = ( id ) => ({
    type: types.productosDelete,
    payload: id
})