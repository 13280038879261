import Swal from 'sweetalert2';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';

export const slidersStartAddNew = ( slider ) => {

    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'slider/new', slider, 'POST' );
            const body = await resp.json();
    
            if( body.ok ) {
                Swal.fire({
                    title: `Slide cargado`,
                    icon: 'success'
                })
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

export const setSliderEdit = ( slider ) => ({
    type: types.sliderEdit,
    payload: slider
})


export const slidersStartEdit = ( slider ) => {

    return async( dispatch ) => {

    try {

        const resp = await fetchSinToken( `slider/${ slider.id }`, slider, 'PUT' );
        const body = await resp.json();

        if( body.ok ) {
            Swal.fire({
                title: `El registro fue actualizado`,
                icon: 'success'
            })

            dispatch( slidersUpdate( body.slider ) )
        }else{
            Swal.fire('Error', body.msg, 'error');
        }


    } catch (error) {
        console.log( error );
        Swal.fire('Error', 'Error en la DB', 'error');
    }

    }
}

export const slidersUpdate = ( user ) => ({
        type: types.slidersUpdate,
        payload: user    
})


export const slidersStartLoading = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchSinToken( 'slider' );
            const body = await resp.json();
    
            if( body.ok ) {
                dispatch( slidersLoaded( body.sliders ) )
    
            }else{
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }
    }
}

const slidersLoaded = ( sliders ) => ({
    type: types.slidersLoaded,
    payload: sliders
})


export const slidersStartDelete = ( id ) => {

    return async( dispatch ) => {
        try {

            const resp = await fetchSinToken( `slider/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire({
                    title: `El registro fue eliminado`,
                    icon: 'success'
                })
                
               dispatch( slidersDelete( id ) )
            }else{
                Swal.fire('Error', body.msg, 'error');
            }


        } catch (error) {
            console.log( error );
            Swal.fire('Error', 'Error en la DB', 'error');
        }

    }
}

const slidersDelete = ( id ) => ({
    type: types.slidersDelete,
    payload: id
})