import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import Swal from 'sweetalert2';
import { ImageInput } from '../components/Widgets';
import { CategoriasSimpleTable } from '../components/CategoriasSimpleTable';
import { useDispatch, useSelector } from 'react-redux';
import { categoriasStartAddNew, categoriasStartEdit } from '../actions/categorias';
import { useParams } from 'react-router';

export const CategoriasForm = () => {

    const { id } = useParams();

    const dispatch = useDispatch();
    const { categoriaEdit } = useSelector(state => state.categorias);

    let title;
    let bajada;
    let iniState;

    if (!id) {
        title = 'Crear una categoria';
        bajada = 'Complete el siguiente formulario con los datos requeridos.';
        iniState = {
            titulo: '',
            descripcion: '',
            imagen: '',
            grupos: [
            ]
        };

    } else {
        title = 'Editar una categoria';
        bajada = 'Modifique los campos que quiera editar.';
        iniState = categoriaEdit;
    }


    const initialState = iniState;
    const [formValues, setFormValues] = useState(initialState);
    const { titulo, descripcion, grupos, orden } = formValues;

    const [gruposList, setGruposList] = useState(initialState.grupos);

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleGuardar = () => {


        if (titulo.trim() === '') {
            Swal.fire({
                icon: 'error',
                title: 'El título es obligatorio'
            })
        } else {
            if (!id) {
                dispatch(categoriasStartAddNew(formValues));
            } else {
                dispatch(categoriasStartEdit(formValues));
            }
        }

    }

    const removerGrupo = (id) => {
        setGruposList(gruposList.filter(function (g) { return g._id !== id }));
        setFormValues({ ...formValues, grupos: grupos.filter(function (g) { return g._id !== id }) })
    }

    const addGrupo = (g) => {
        setGruposList([...gruposList, g]);
        setFormValues({ ...formValues, grupos: [...grupos, g] })
    }

    const returnFuncOneImage = (imagen) => {
        setFormValues({ ...formValues, imagen });
    }

    return (
        <section className="products__wrapper">

            <div className="row">
                <h2 className="title">{title}</h2>
                <Link to="/productos/categorias" className="button-volver"><ArrowBackIcon /> Volver al listado de categorías</Link>
            </div>
            <p className="bajada">{bajada}</p>

            <form className="input-form" onSubmit={handleSubmit}>

                <span className="input-label">Título</span>
                <div className="input triple">
                    <input
                        placeholder="Introduzca aquí el nombre de la categoría"
                        name="titulo"
                        value={titulo}
                        onChange={handleInputChange}
                        autoComplete="off"
                    />


                    <ImageInput
                        label="Imagen"
                        pholder="Subir imagen"
                        returnFuncOneImage={returnFuncOneImage}
                    />

                </div>

                <span className='input-label'>Orden</span>
                <div>
                    <input
                        placeholder="Orden en el menu"
                        name="orden"
                        value={orden}
                        onChange={handleInputChange}
                        autoComplete="off"
                        type="number"
                    />
                </div>
                
                <br/>

                <span className="input-label">Descripción</span>
                <div>
                    <textarea
                        name="descripcion"
                        type='text'
                        value={descripcion}
                        onChange={handleInputChange}
                    >
                    </textarea>
                </div>

                <span className="input-label">Grupos</span>

                <CategoriasSimpleTable
                    titulo="Nombre"
                    payload={gruposList}
                    removerGrupo={removerGrupo}
                    addGrupo={addGrupo}
                />

                <button onClick={handleGuardar}
                    className="form-add mt-40"

                ><SaveIcon />Guardar categoría</button>
            </form>

        </section>
    )

}