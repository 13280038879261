import Swal from 'sweetalert2';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';
 
export const startLogin = (user, password) =>{
    return async( dispatch ) =>{
        
        const resp = await fetchSinToken('auth', {user, password}, 'POST');
        const body = await resp.json();

        if( body.ok ) {
            const { uid, user } = body;
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime() );

            localStorage.setItem('logged', true);
            localStorage.setItem('uid', uid );
            localStorage.setItem('user', user );

            dispatch( login({
                uid: uid,
                user: user
            }) )


        }else{
            Swal.fire('Error', body.msg, 'error');
        }

    }
}
 
export const login = ( user ) =>(
    {
        type: types.authLogin,
        payload: user
    }
)

export const persistirLogin = ( uid, user, isLogged ) => {

    if ( isLogged ) {
        return ( dispatch ) => {
            dispatch( login({
                uid: uid,
                user: user
            }) )

        }

    } else {
        return ( dispatch ) => {
            dispatch( startLogout() )
        }
    }
}

export const startLogout = () => {
    return ( dispatch ) => {

        localStorage.clear();
        dispatch( logout() );
    }
}

const logout = () => ({ type: types.authLogout });